@import 'assets/styles/mixins.scss';

.container {
  .text {
    margin-bottom: 32px;
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.22px;
    color: #17212b;
  }
  .inputWrapper {
    margin-bottom: 32px;
  }

  .button {
    margin-bottom: 24px;
  }
}
