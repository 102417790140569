@import 'assets/styles/mixins.scss';

.cardInput {
  //   grid-template-columns: 1fr 1fr 1fr;
  // display: grid;
  // grid-template-columns: 1fr minmax(0px, 124px) minmax(0, 124px);
  width: 100%;
  border: 1px solid rgba(23, 33, 43, 0.1);
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;
  @include transition;
  background-color: transparent;
  display: flex;
  position: relative;

  &:hover {
    border: 1px solid rgba(23, 33, 43, 0.16);
  }

  &:focus-within {
    border: 1px solid var(--Brand-Green);
  }

  &.error {
    border: 1px solid #f5495d;
  }
}

.inputLabel {
  display: flex;
  // flex-direction: column;\

  align-items: center;
  width: 100%;
  //   min-width: 350px;
  position: relative;
  //   border: 1px solid red;

  border-radius: 10px;
  //overflow: hidden;

  &.active {
    &::after {
      content: '*';
      color: black;
      // min-width: 5px;
      // min-height: 5px;
      // width: 5px;
      // height: 5px;
      // background: red;
      position: absolute;
      top: 0;
      left: 0;
      font-size: 18px;
      // transform: translateY(50);
      top: calc(50% - 4px);
      left: 53px;
    }

    input {
      padding-left: 20px;
    }
  }

  &:focus-within {
    label {
      top: 15px;
      //   padding-left: calc(12px + 25%);
      // padding-left: 15px;
      // transform: scale(0.75);
      font-size: 12px;
      //   transform: translate(0, -12px) scale(0.75);
    }

    input {
      &::placeholder {
        color: #7d8288;
      }
    }
  }

  input {
    width: 100%;
    box-sizing: border-box;
    padding: 25px 11px 11px 11px;
    outline: 0;
    // border: 1px solid #ddd;
    border: none;
    border-radius: 10px;
    background: #fff;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.13px;
    &::placeholder {
      @include transition;

      color: transparent;
    }
  }

  .label {
    padding: 0 12px;
    pointer-events: none;
    white-space: nowrap;

    position: absolute;
    // transform: translate(0, 14px) scale(1);
    top: 50%;
    transform: translate(0, -50%);
    transform-origin: top left;
    transition: all 0.2s ease-out;

    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 133.333% */
    letter-spacing: -0.13px;

    color: #7d8288;

    &.active {
      top: 15px;

      //   padding-left: calc(12px + 25%);
      // padding-left: 15px;
      // transform: scale(0.75);
      font-size: 12px;
    }
  }
}

.error {
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 123% */

  letter-spacing: -0.02em;

  color: #ff3d4d;
}

.cardIcon {
  position: absolute;
  left: 0px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;

  svg {
    width: 24px;
    height: 16px;
  }
}
