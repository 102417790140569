@import 'assets/styles/mixins.scss';

.wrapper {
  // padding: 24px;
  padding: 24px 24px 0 24px;
  display: flex;
  justify-content: space-between;

  .btnDetails {
    @include Inter_15_600;
    background: var(--PAA_Brand_Purple);
    color: var(--white);
  }

  @include respond(tabletS) {
    padding: 24px 16px 0px 16px;
  }

  .main {
    display: flex;
    flex-direction: column;
  }

  .button {
    display: inline-flex;
    align-items: center;
    column-gap: 6px;
    margin-bottom: 8px;
    cursor: pointer;
    width: fit-content;
    user-select: none;

    .buttonIcon {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .buttonText {
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      @include respond(tablet) {
        max-width: 300px;
      }

      @include respond(tabletS) {
        max-width: 300px;
      }

      @include respond(mobileL) {
        max-width: 240px;
      }

      @include respond(mobile) {
        max-width: 220px;
      }

      .textName {
        @include tCaption;
        color: rgba(23, 33, 43, 0.64);
      }
    }
  }

  .amount {
    display: inline-flex;
    align-items: center;
    column-gap: 8px;
    position: relative;
    // cursor: pointer;
    width: fit-content;

    .amountValue {
      display: flex;
      // user-select: none;

      .amountText {
        @include tAmount;
        color: #17212b;
      }
    }

    .amount__icon {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      @include transition;

      &.active {
        transform: rotate(180deg);
      }
    }

    .amount__menu {
      position: absolute;
      width: 334px;
      z-index: 222;
      top: 44px;
      left: -24px;
      background-color: #ffffff;
      border-radius: 16px;
      box-shadow: 0px 8px 16px rgba(37, 33, 41, 0.04), 0px 0px 6px rgba(37, 33, 41, 0.04);

      @include respond(tabletS) {
        width: 100%;
        left: 0px;
      }

      .amount__menu-wrapper {
        padding: 24px;

        .menu__content {
          .content__list {
            .list__item {
              .item__name {
                .name__text {
                  @include tCaption;
                  color: rgba(23, 33, 43, 0.64);
                }
              }
              .item__number {
                .item__number {
                  @include tCaption_3;
                  color: #000000;
                }
              }
            }

            .list__item:not(:last-child) {
              margin-bottom: 16px;
            }
          }
        }
      }
    }
  }
}
