@import 'assets/styles/mixins.scss';

.inputWrapper {
  margin-bottom: 24px;
}

.button {
  margin-top: 32px;
  margin-bottom: 12px;
}

.wrapper {
  border-radius: 16px;

  box-shadow: var(--Box_Shadow_Elevation_1_Bordered);

  .inputs {
    .input:not(:last-child) {
      margin-bottom: 12px;
    }
  }

  .card_invalid {
    @include transition;
    color: #ff3d4d;
    @include tCaption_2;
  }
}
