@import 'assets/styles/mixins.scss';

.container {
  display: flex;
  flex-direction: column;

  .header {
    .headerTitle {
      font-weight: 600;
      font-size: 25px;
      line-height: 28px;
      letter-spacing: -0.5px;
      color: #0d1019;
    }
    margin-bottom: 32px;
  }

  .info {
    margin-bottom: 32px;
    .infoItem {
      margin-bottom: 24px;

      &:last-of-type {
        margin-bottom: 0;
      }

      .itemTitle {
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: -0.22px;
        color: #7d8288;
      }

      .itemText {
        font-weight: 400;
        font-size: 17px;
        line-height: 24px;
        letter-spacing: -0.22px;
        color: #17212b;
        word-break: break-all;
      }
    }
  }

  .expire {
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.22px;
    color: #17212b;

    padding-top: 12px;

    & > div {
      margin: 0 -16px;

      @include respond(tabletS) {
        margin: 0;
      }
    }
  }
}
