@import "src/assets/styles/mixins";
@import "src/assets/styles/variables";
.snackbar {
  padding: 17px 25px 17px 19px;
  border-radius: 10px;
  min-width: 320px;
  z-index: 999;
  position: relative;

  @include respond(mobile) {
    min-width: 100%;
    padding: 17px 15px 17px 8px;
  }

  &__container {
    display: grid;
    grid-template-columns: 18px auto auto 10px;
    align-items: center;

    &_data {
      gap: 4px;
      margin-left: 16px;
      max-width: 90%;
      display: flex;
      flex-direction: column;

      &_title{
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
        @include tCaption_3;

        padding-right: 15px;

        @include respond(mobileM) {
          font-size: 14px;
        }
      }

      &_message {
        text-overflow: ellipsis;
        width: 100%;
        overflow: hidden;
        @include tSubtitle;

        padding-right: 15px;

        @include respond(mobileM) {
          font-size: 13px;
        }
      }
    }

    &_button {
      border: 1px solid var(--BLACK_GRAY_56);
      border-radius: 8px;
      padding: 14px 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 16px;

      @include tSubtitle;

      @include respond(mobile) {
        margin: 0 15px 0 12px;
        padding: 10px;
      }
    }

    &_buttonClose {
      outline: none;
      background: transparent;
      border: 0;
      cursor: pointer;
      @include respond(mobile) {
        padding-top: 5px;
        position: absolute;
        top: 50%;
        right: 15px;
        transform: translateY(-50%);
      }
    }
  }
}

.background {
  background: #ffffff;
  border-radius: 10px;
}

.success {
  background: var(--ACCENTS_SNACKBAR_SUCCESS);
}

.warning {
  background: var(--ACCENTS_SNACKBAR_WARNING);
}

.info {
  background: var(--ACCENTS_SNACKBAR_INFO);
}

.error {
  background: var(--ACCENTS_SNACKBAR_NEGATIVE);
}
