@import 'assets/styles/mixins.scss';

.errorMessage {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #ff3d4d;
}

.input{
  border-radius: inherit;

  &:-webkit-autofill{
    border-radius: inherit;
  }
}

