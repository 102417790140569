@import 'assets/styles/mixins.scss';
.switch {
  margin-bottom: 32px;

  :global {
    .MuiToggleButtonGroup-root {
      button {
        display: flex;
        column-gap: 10px;
        flex-direction: row-reverse;

        @include respond(tabletS) {
          flex-direction: column;
          padding: 10px !important;
        }

        .item__currency-name {
          color: rgba(23, 33, 43, 0.36);
        }
      }
    }
  }
}

.input {
  
  margin-bottom: 32px;
}
.button {
  margin-bottom: 12px;
}
