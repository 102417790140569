@import 'assets/styles/mixins.scss';

.container {
  height: 100%;
  width: 100%;

  .text {
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.22px;
    color: #17212b;
    margin-bottom: 32px;
  }

  .content {
    margin-bottom: 32px;
  }
}
