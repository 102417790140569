@import 'assets/styles/mixins.scss';

.container {
  .offerText {
    width: 100%;
    max-width: 461px;
    margin: 0 auto;

    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    text-align: center;

    color: rgba(13, 16, 25, 0.56);
    margin-bottom: 12px;

    a {
      color: #0d1019;
    }
  }

  .paymentSystems {
    display: block;
    width: 100%;
    max-width: 490px;
    margin: 0 auto 24px;
  }
}
