@import "assets/styles/mixins.scss";

.offerWrapper {
  margin-top: 12px;
}

.warningFormStatus {
  margin: 32px 0;
  @include respond(tabletS) {
    margin: 24px 0;
  }
}
