@import 'assets/styles/mixins.scss';

.description {
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.22px;
  color: #17212b;
  margin-bottom: 32px;
}
