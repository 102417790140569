body {
  // Constant colors
  --black: #0d1019;
  --white: #ffffff;
  --Brand-Green: #b1f042;

  // Foundation
  --primary: #000000;
  --secondary: #ffffff;
  --negative: #ff3d4d;
  --warning: #ffa64d;
  --success: #3ac270;

  // Background colors
  --backgroundPrimary: #ffffff;
  --backgroundSecondary: #f6f6f6;
  --backgroundTertiary: #ebebeb;
  --backgroundInverted: #000000;

  --backgroundPersonalControl: linear-gradient(
    90deg,
    rgba(246, 246, 246, 1) 0%,
    rgba(246, 246, 246, 1) 0%
  );

  --backgroundBusinessControl: linear-gradient(
    84.7deg,
    #1d1d1d -0.45%,
    rgba(29, 29, 29, 0.8) 102.11%
  );

  // Background Alpha colors
  --backgroundAlpha_1: rgba(0, 0, 0, 0.03);
  --backgroundAlpha_2: rgba(0, 0, 0, 0.04);
  --backgroundAlpha_3: rgba(0, 0, 0, 0.08);
  --backgroundAlpha_4: rgba(0, 0, 0, 0.12);
  --backgroundAlpha_5: rgba(0, 0, 0, 0.16);
  --backgroundAlpha_6: rgba(0, 0, 0, 0.6);
  --backgroundAlpha_7: rgba(0, 0, 0, 0.7);

  --backgroundAlpha_Negative_1: rgba(255, 61, 77, 0.06);
  --backgroundAlpha_Negative_2: rgba(255, 61, 77, 0.16);

  --backgroundAlpha_Success_1: rgba(58, 194, 112, 0.06);
  --backgroundAlpha_Success_2: rgba(58, 194, 112, 0.16);

  --backgroundAlpha_Warning_1: rgba(255, 166, 77, 0.06);
  --backgroundAlpha_Warning_2: rgba(255, 166, 77, 0.16);

  // Border colors
  --borderPrimary: #e8e8e8;
  --borderInverted: #000000;

  // Border Alpha colors
  --borderAlpha_1: rgba(0, 0, 0, 0.1);
  --borderAlpha_Inverted_1: rgba(255, 255, 255, 0.3);

  // Content colors
  --contentPrimary: #000000;
  --contentSecondary: #747474;
  --contentTertiary: #a3a3a3;
  --contentOnColor: #ffffff;
  --contentDisabled: #f6f6f6;

  //Accents
  --PAA_Negative: #ff3d4d;
  --PAA_Positive: #3ac270;
  --PAA_Warning: #ffa64d;
  --PAA_Brand_Primary: #b1f042;
  --PAA_Brand_Secondary: #276460;
  --PAA_Brand_Purple: #9747ff;

  //Accents Tinted
  --PAB_Negative_Tinted: rgba(255, 61, 77, 0.16);
  --PAB_Negative_Tinted_Lighter: rgba(255, 61, 77, 0.06);
  --PAB_Positive_Tinted: rgba(58, 194, 112, 0.16);
  --PAB_Positive_Tinted_Lighter: rgba(58, 194, 112, 0.06);
  --PAB_Alert_Tinted: rgba(255, 166, 77, 0.16);
  --PAB_Alert_Tinted_Lighter: rgba(255, 166, 77, 0.09);
  --PAB_Brand_Primary_Tint: rgba(255, 85, 51, 0.06);

  //Text
  --PA_Text_Primary: #252129;
  --PA_Text_Secondary: #666666;
  --PA_Text_Tertiary: #ffffff;
  --PA_Text_Placeholder: #a2a2a3;
  --PA_Text_Disabled: #dcdbdd;

  //Overlay Text
  --PA_Overlay_Text_Primary: #ffffff;
  --PA_Overlay_Text_Secondary: rgba(255, 255, 255, 0.64);

  //Icons
  --PA_Icon_Primary: #252129;
  --PA_Icon_Secondary: #9f9f9f;
  --PA_Icon_Overlay_Primary: #ffffff;

  //UI Elements
  --PA_Border_Default: #ebebeb;
  --PA_Surface_Tinted: #f7f7f7;
  --PA_Surface_Tinted_Lighter: #fbfbfb;
  --PA_Surface_Tinted_3: #ebebeb;

  --PA_Background_Primary: #ffffff;
  --PA_Background_Secondary: #252129;

  // SNACKBAR
  --ACCENTS_SNACKBAR_SUCCESS: rgba(98, 224, 134, 0.08);
  --ACCENTS_SNACKBAR_NEGATIVE: rgba(255, 79, 99, 0.06);
  --ACCENTS_SNACKBAR_INFO: rgba(69, 136, 230, 0.06);
  --ACCENTS_SNACKBAR_WARNING: rgba(255, 206, 71, 0.12);

  // BOX SHADOW

  --Elevation_1_Bordered: 0px 0px 0px 1px #f4f5f6, 0px 8px 16px rgba(37, 33, 41, 0.04),
    0px 0px 6px rgba(37, 33, 41, 0.04);

  --Elevation_1: 0px 8px 16px rgba(37, 33, 41, 0.04), 0px 0px 6px rgba(37, 33, 41, 0.04);

  --Elevation_2_Bordered: 0px 0px 0px 1px #f4f5f6, 0px 16px 32px rgba(37, 33, 41, 0.08),
    0px 0px 12px rgba(37, 33, 41, 0.04);

  --Elevation_2: 0px 16px 32px rgba(37, 33, 41, 0.08), 0px 0px 12px rgba(37, 33, 41, 0.04);

  --Slider_Button_Shadow: 0px 8px 16px rgba(0, 0, 0, 0.04), 0px 0px 6px rgba(0, 0, 0, 0.04);

  //DARK MODE DARK MODE DARK MODE DARK MODE
  &.dark-content {
    // Foundation
    --primary: #ffffff;
    --secondary: #000000;
    --negative: #ff3d4d;
    --warning: #ffa64d;
    --success: #3ac270;

    // Background colors
    --backgroundPrimary: #000000;
    --backgroundSecondary: #242424;
    --backgroundTertiary: #373737;
    --backgroundInverted: #ffffff;

    --backgroundPersonalControl: linear-gradient(
      90deg,
      rgba(36, 36, 36, 1) 0%,
      rgba(36, 36, 36, 1) 0%
    );
    --backgroundBusinessControl: linear-gradient(
      84.7deg,
      rgba(79, 79, 79, 0.96) -0.45%,
      rgba(79, 79, 79, 0.67) 44.8%,
      rgba(79, 79, 79, 0.31) 102.11%
    );

    // Background Alpha colors
    --backgroundAlpha_1: rgba(255, 255, 255, 0.14);
    --backgroundAlpha_2: rgba(255, 255, 255, 0.16);
    --backgroundAlpha_3: rgba(255, 255, 255, 0.18);
    --backgroundAlpha_4: rgba(255, 255, 255, 0.24);
    --backgroundAlpha_5: rgba(255, 255, 255, 0.32);
    --backgroundAlpha_6: rgba(255, 255, 255, 0.8);
    --backgroundAlpha_7: rgba(255, 255, 255, 0.9);

    --backgroundAlpha_Negative_1: rgba(255, 61, 77, 0.24);
    --backgroundAlpha_Negative_2: rgba(255, 61, 77, 0.32);

    --backgroundAlpha_Success_1: rgba(58, 194, 112, 0.24);
    --backgroundAlpha_Success_2: rgba(58, 194, 112, 0.32);

    --backgroundAlpha_Warning_1: rgba(255, 166, 77, 0.24);
    --backgroundAlpha_Warning_2: rgba(255, 166, 77, 0.32);

    // Border colors
    --borderPrimary: #444444;
    --borderInverted: #ffffff;

    // Border Alpha colors
    --borderAlpha_1: rgba(255, 255, 255, 0.3);
    --borderAlpha_Inverted_1: rgba(0, 0, 0, 0.1);

    // Content colors
    --contentPrimary: #ffffff;
    --contentSecondary: #c5c5c5;
    --contentTertiary: #888888;
    --contentOnColor: #000000;
    --contentDisabled: #2d2d2d;
  }
}
