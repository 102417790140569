@import 'assets/styles/mixins.scss';

.container {
  // display: grid;
  // grid-template-columns: 304px max-content 1fr;
  // height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  // height: 50vh;
  //   padding: 24px;

  @include respond(tabletS) {
    height: 50vh;
  }

  .sidebar {
    display: flex;
    flex-direction: column;
    padding: 24px;

    .item {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 12px;

      .image {
        margin-right: 12px;
      }
    }

    .subtitle {
      margin-top: 21px;
      margin-bottom: 10px;
      margin-left: 12px;
    }
  }

  .divider {
    margin-top: 24px;
    margin-bottom: 24px;
  }
}
