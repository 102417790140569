@import 'assets/styles/mixins.scss';

.widgetPreview {
  padding: 20px;
  //   box-shadow: 0px 16px 44px rgba(89, 102, 132, 0.1);
  border-radius: 12px;
  border: 1px solid #f0f2f5;
  background: #ffffff;
  @include transition;
  user-select: none;

  max-width: 344px;
  overflow: hidden;
  .amount {
    @include transition;
  }

  .description {
    margin-bottom: 12px;

    @include transition;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .mockInput {
    border-radius: 8px;
    border: 1px solid transparent;
    padding: 20px 16px;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.13px;
    user-select: none;
    box-sizing: border-box;
    overflow: hidden;
    @include transition;

    &.inputEmail {
      margin-bottom: 12px;
    }
  }

  .inputWrapper {
    overflow: hidden;
    margin-bottom: 12px;
  }

  .payButton {
    padding: 16px;
    background: var(--PAA_Brand_Primary);
    border-radius: 10px;
    width: 100%;
    color: #111111;
    font-weight: 600;
    font-size: 15px;
    line-height: 24px;
    border: 1px solid transparent;
    @include transition;
    cursor: pointer;

    &:disabled {
      opacity: 0.3;
    }
  }

  .safePayments {
    opacity: 0.64;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #111111;
    margin-top: 14px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    @include transition;

    svg {
      margin-left: 6px;
    }
  }

  &.large {
    .amount {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
    }

    .description {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }
  }

  &.medium {
    .amount {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }

    .description {
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
    }
  }

  &.small {
    padding: 20px 20px 14px 20px;
    .amount {
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
    }

    .description {
      font-weight: 400;
      font-size: 12px;
      line-height: 24px;
    }

    .payButton {
      padding: 15px 20px;
    }
  }

  &.white {
    background: #ffffff;
    .amount {
      color: #111111;
    }

    .description {
      color: #111111;
    }
    .mockInput {
      border-color: rgba(13, 16, 25, 0.12);
      color: rgba(13, 16, 25, 0.56);
    }

    .safePayments {
      color: #111111;
    }
  }

  &.black {
    background: #17212b;
    .amount {
      color: #ffffff;
    }

    .description {
      color: #ffffff;
    }

    .innerInput {
      //   border: 1px solid rgba(255, 255, 255, 0.56);
      box-sizing: border-box;

      input {
        color: #ffffff;
      }

      label {
        color: #ffffff;
      }
    }

    .safePayments {
      color: #ffffff;
    }
  }
}
