@import 'assets/styles/mixins.scss';

.container {
  // padding: 24px;
  padding: 24px 24px 0 24px;
  display: flex;
  justify-content: space-between;

  .backButton {
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  .details {
    border-radius: 10px;
  }
}
