.containerInput {
  .inputField {
    border-radius: 10px !important;
    width: 100% !important;
    border: 1px solid rgba(23, 33, 43, 0.1) !important;
    box-shadow: none !important;
    padding-top: 25px;
    padding-bottom: 11px;

    font-family: 'Inter';
    font-size: 15;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.13px;

    &:hover {
      border-color: rgba(23, 33, 43, 0.16) !important;
    }

    &:focus {
      border-color: #b1f042 !important;

      &.error {
        border-color: rgba(255, 61, 77, 0.72) !important;
      }
    }

    &.error {
      background: rgba(255, 61, 77, 0.02);
      border-color: rgba(255, 61, 77, 0.36) !important;
    }
  }

  .input__error {
    position: absolute;
    color: var(--PAA_Negative);
    font-size: 12px;
    margin-left: 5px;
  }

  :global {
    .special-label {
      left: 48px;
      top: 50%;
      transform: translateY(-50%);
      padding: 0 12px;

      user-select: none;
      pointer-events: none;

      font-size: 15px;
      font-family: 'Inter';
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      color: #7d8288;
      background-color: transparent;
      transition: all 0.3s;
    }
  }

  :global(:not(.empty) .special-label),
  :global(:focus-within .special-label) {
    top: 15px;
    font-size: 12px;
  }
}

.phoneInputDropdown {
  box-shadow: 0px 16px 40px rgba(103, 106, 115, 0.24) !important;
  border-radius: 16px !important;
  overflow-y: auto !important;

  &::-webkit-scrollbar {
    width: 3px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 512px;
    background-color: rgba(23, 33, 43, 0.12);
  }

  &::-webkit-scrollbar-track {
    width: 3px;
    margin: 12px 0;
  }

  //Костыль, чтоб скрыть казахский номер с начинающийся с +7, через библу не получается
  :global {
    .country[data-country-code='kz'] {
      &:nth-child(2) {
        display: none;
      }
    }
  }
}

.phoneInputContainer {
  :global {
    .flag-dropdown {
      // display: none !important;

      [class='flag 0'] {
        // display: none !important;
        background-image: none;
        background-color: rgba(23, 33, 43, 0.04);
      }
    }
  }
}

.error {
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: -0.02em;
  color: #ff3d4d;
}
