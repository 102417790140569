@import 'assets/styles/mixins.scss';

.dropdown__language {
  position: relative;
  display: inline-block;
  align-items: baseline;
  @include transition;

  .dropdown-btn {
    display: flex;
    align-items: center;
    column-gap: 6px;
    width: 100%;
    padding: 8px 0px;
    border: none;
    cursor: pointer;
    color: rgba(23, 33, 43, 0.56);
    background-color: #f6f6f7;
    font-size: 17px;
    line-height: 24px;
    @include transition;
    text-transform: capitalize;

    .dropdown__flag {
      display: flex;
      align-items: center;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    .dropdown__arrow {
      @include transition;

      &.active {
        transform: rotate(180deg);
      }
    }
  }

  .dropdown__content {
    position: absolute;
    overflow: hidden;
    // bottom: 40px;
    border-radius: 12px;
    box-shadow: 0px 8px 20px #ebebeb;
    background-color: #ffffff;
    min-width: 100%;
    z-index: 10;
    color: rgba(23, 33, 43, 0.56);

    &.top {
      top: 40px;
    }

    &.bottom {
      bottom: 40px;
    }

    .dropdown__item {
      cursor: pointer;
      padding: 8px 12px;
      background-color: var(--white);
      display: flex;
      column-gap: 6px;

      @include transition;

      &:hover {
        @include transition;
        background-color: #ebebeb;
        overflow: hidden;
      }

      .item__text {
        font-size: 17px;
        line-height: 24px;
        overflow: hidden;
        text-transform: capitalize;
      }

      .item__text {
        font-size: 17px;
        line-height: 24px;
      }
    }
  }
}
