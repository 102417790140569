@import 'assets/styles/mixins.scss';

.container {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.amount {
  white-space: nowrap;
}
