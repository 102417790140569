@import 'assets/styles/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 8px;

  .icon {
    width: 56px;
    height: 56px;
    margin-bottom: 24px;
  }

  .title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    letter-spacing: -0.18px;
    color: #17212b;
    margin-bottom: 12px;
    text-align: center;
  }

  .text {
    font-weight: 400;
    font-size: 17px;
    line-height: 24px;
    letter-spacing: -0.22px;
    color: #17212b;
    margin-bottom: 32px;
    text-align: center;
  }
}
