.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .content {
    margin-top: 64px;
    width: min(648px, 100%);

    .form {
      background: var(--white);
      border-radius: 24px;
      padding: 28px 40px 40px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .button {
        margin-top: 16px;
      }
    }
  }
}
