@import 'assets/styles/mixins.scss';

.iconButton {
  background: transparent;
  border: none;
  padding: 0;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 512px;
  @include transition;
  cursor: pointer;
  color: inherit;

  &.ghost {
    &:hover {
      background: rgba(13, 16, 25, 0.06);
    }

    &:active {
      background: rgba(13, 16, 25, 0.12);
    }
  }

  &:disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}
