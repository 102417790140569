@import '../../assets/styles/mixins.scss';

.btn {
  display: flex;
  justify-content: center;
  align-items: center;

  border: 1.2px solid transparent;
  border-radius: 10px;

  @include Inter_15_600;
  color: var(--black);

  cursor: pointer;

  height: fit-content !important;

  transition: 0.6s ease;

  @include respond(tablet) {
    width: 100%;
  }

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
  }

  &.small {
    padding: 8px 16px;
  }

  &.medium {
    padding: 12px 20px;
  }

  &.large {
    padding: 19px 24px;
  }

  &.primary {
    background: var(--PAA_Brand_Primary);
    color: #17212b;

    &:hover {
      background: var(--PAA_Brand_Primary);
      opacity: 0.7;
      color: #17212b;
    }

    &:active {
      background: var(--PAA_Brand_Primary);
    }

    &:disabled {
      // background: var(--PA_Border_Default);
      opacity: 0.3;
      // color: #17212b;
    }
  }
}

.loader {
  svg {
    display: block;
  }

  position: absolute;

  width: fit-content;
  height: fit-content;

  -webkit-animation: spin 0.75s linear infinite;
  -moz-animation: spin 0.75s linear infinite;
  animation: spin 0.75s linear infinite;
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(-360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
