@import 'assets/styles/mixins.scss';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.29;

    color: #23bb29;
    margin-left: 7px;
  }
}
