@import 'assets/styles/mixins.scss';

.sidebar {
  overflow: auto;
  width: 100%;
  overflow-x: hidden;
  transition: visibility 0.2s;
  scrollbar-color: lightgray white;
  scrollbar-width: none;

  &:hover {
    scrollbar-width: thin;
  }

  &::-webkit-scrollbar {
    width: 3px;
    background: #ffffff;
    margin: 24px 0;
    visibility: hidden;

    @include respond(laptopL) {
      opacity: 0;
    }
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 512px;
    background-color: rgba(23, 33, 43, 0.12);
    visibility: hidden;

    @include respond(laptopL) {
      opacity: 0;
    }
  }

  &::-webkit-scrollbar-track {
    width: 3px;
    visibility: hidden;

    @include respond(laptopL) {
      opacity: 0;
    }
  }

  &:hover {
    transition: visibility 0s 0.2s;

    &::-webkit-scrollbar {
      visibility: visible;
    }

    &::-webkit-scrollbar-thumb {
      visibility: visible;
    }

    &::-webkit-scrollbar-track {
      visibility: visible;
    }
  }

  .burger-wrapper {
    display: none;
    overflow: hidden;

    @include respond(laptopL) {
      display: flex;
      justify-content: center;
      max-width: 88px;
      padding: 24px 16px 4px 16px;
      box-sizing: border-box;
      background: white;
      z-index: 3;
    }

    &.active {
      width: 100%;
    }

    @include respond(tabletS) {
      display: none;
    }

    .burger-button {
      width: 24px;
      height: 24px;
      background: none;

      padding: 0;
      border: none;
      cursor: pointer;
      transform: rotate(0deg);
      @include transition;

      &.active {
        transform: rotate(180deg);
      }
    }
  }
  .invoice-method {
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;

    @include respond(laptopL) {
      max-height: 609px;
    }

    @include respond(tabletS) {
      max-height: 100%;
      height: 100%;
      padding-top: 0;
    }

    .invoice-method-container {
      padding: 0 16px 16px 16px;

      @include respond(tabletS) {
        padding: 24px 16px 0px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        padding: 0;
      }

      .invoice-method__title {
        margin-bottom: 24px;
        position: relative;
        z-index: 19;

        .title__name {
          @include tTitle_2;
          color: #17212b;
        }
      }

      .invoice-method__items {
        min-width: 56px;
        .items__list {
          display: flex;
          flex-direction: column;

          button {
            margin-bottom: 4px;

            &:last-of-type {
              margin-bottom: 0;
            }
          }

          .list__sidebar-title {
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            line-height: 14px;
            text-transform: uppercase;
            color: rgba(23, 33, 43, 0.36);
            padding: 10px 10px;
            padding-left: 10px;
            padding-right: 10px;
            display: inline-block;
            overflow: hidden;
            opacity: 1;
            @include transition;

            &.invisible {
              opacity: 0;
            }
          }
        }
      }
    }
  }

  .invoice-method::-webkit-scrollbar {
    width: 3px;
    background: #ffffff;
  }

  .invoice-method::-webkit-scrollbar-thumb {
    border-radius: 512px;
    background-color: rgba(23, 33, 43, 0.12);
  }

  .invoice-method::-webkit-scrollbar-track {
    width: 3px;
  }
}
