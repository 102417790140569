@import 'assets/styles/mixins.scss';

.stack {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;

  .container {
    display: grid;
    align-items: center;
    grid-template-columns: 48px auto;
    grid-template-areas: 'logo text';
    column-gap: 12px;
    margin-top: 12px;

    @include respond(tabletS) {
      grid-template-columns: 36px auto auto;
      grid-template-areas: 'button text logo';
      justify-content: space-between;
    }

    .iconBack {
      grid-area: button;
      background-color: transparent;
      border: none;
      display: none;
      padding: 0;

      @include respond(tabletS) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .iconService {
      grid-area: logo;
      width: 48px;
      height: 48px;
      @include respond(tabletS) {
        width: 32px;
        height: 32px;
      }
    }

    .title {
      grid-area: text;
      font-family: 'Inter';
      font-size: 25px;
      font-style: normal;
      font-weight: 600;
      line-height: 28px; /* 112% */
      letter-spacing: -0.5px;
      color: #17212b;

      @include respond(tabletS) {
        font-size: 22px;
        line-height: 24px;
      }
    }
  }
}
