@import 'assets/styles/mixins.scss';

.footer {
  .footer-wrapper {
    padding: 32px 12px;
    display: grid;
    justify-content: space-between;
    grid-template-areas: 'info tel';

    @include respond(tabletS) {
      grid-template-areas:
        'info'
        'tel';
    }

    .footer__info {
      grid-area: info;

      @include respond(tabletS) {
        margin-bottom: 24px;
      }

      .info__company {
        margin-bottom: 4px;

        .company__text {
          @include tSubtitle;
          color: rgba(23, 33, 43, 0.36);
        }
      }

      .info__link {
        display: flex;
        column-gap: 12px;
        margin-bottom: 12px;

        .link__public-offer {
          .public-offer__text {
            @include tSubtitle;
            color: rgba(23, 33, 43, 0.56);
          }
        }

        .link__support {
          .support__text {
            @include tSubtitle;
            color: rgba(23, 33, 43, 0.56);
          }
        }
      }
    }

    .footer__tel {
      display: flex;
      flex-direction: column;

      .tel__number {
        display: flex;
        justify-content: right;

        @include respond(tabletS) {
          justify-content: left;
        }

        .number__text {
          @include tSubtitle_2;
          color: #17212b;
        }
      }

      .tel__description {
        display: flex;
        justify-content: right;

        @include respond(tabletS) {
          justify-content: left;
        }

        .description__text {
          @include tSubtitle;
          color: rgba(23, 33, 43, 0.36);
        }
      }
    }
  }
}
