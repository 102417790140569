@import "assets/styles/mixins.scss";

.formStatus {
  padding: 16px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 16px;

  &.warning {
    background: var(--Accents-Warning-12, #fff9e8);
    &.outlined {
      padding: 15px;
      border: 1px solid var(--Accents-Warning-50, #ffe7a3);
      background: #fff;
    }
    .icon {
      path {
        fill: #ffce47;
      }
    }
  }

  &.info {
    background: #f4f8fe;
  }

  &.error {
    background-color: #fff5f6;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;

    .title {
      @include tCaption_3;
      color: #0d1019;
    }

    .text {
      //@include Inter400_15;
      color: var(--BLACK_GRAY_56, #0d10198f);
    }
  }
}

.icon {
  display: flex;
  align-items: center;
}
