@import 'assets/styles/mixins.scss';

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  .wrapper {
    max-width: 544px;
    width: 100%;

    .serviceHeader {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 32px;
      margin-top: 24px;

      .image {
        margin-right: 12px;
      }

      @include respond(tabletS) {
        padding: 0 16px;
      }
    }

    .serviceContent {
      @include respond(tabletS) {
        padding: 0 16px;
      }

      .description {
        margin-bottom: 32px;

        .descriptionText {
          margin-bottom: 12px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      .inputs {
        margin-bottom: 32px;
      }

      .button {
        margin-bottom: 12px;
      }

      .offer {
        .offerText {
          margin-bottom: 12px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
