@import 'assets/styles/mixins.scss';

.container {
  // max-width: 239px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .wrapper {
    width: 100%;
    max-width: 239px;

    .item {
      padding: 12px;
      border-radius: 512px;
      display: flex;
      align-items: center;

      .icon {
        width: 32px;
        height: 32px;
        background: rgba(23, 33, 43, 0.24);
        opacity: 0.36;
        border-radius: 512px;
        margin-right: 12px;
      }

      .text {
        width: 112px;
        height: 22px;
        background: #d9d9d9;
        border-radius: 20px;
      }

      &.active {
        background: #f6f6f7;

        & .icon {
          opacity: 1;
        }
      }
    }

    .description {
      margin-top: 25px;
      font-weight: 400;
      font-size: 17px;
      line-height: 22px;
      text-align: center;
      letter-spacing: -0.22px;
      color: #17212b;
    }
  }
}
