@import 'assets/styles/mixins.scss';

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .switch {
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 32px;
  }
  .email {
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 32px;
  }
  .button {
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 12px;
  }
}
