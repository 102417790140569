@import 'assets/styles/mixins.scss';

.container {
  display: grid;
  grid-template-columns: 1fr 160px;
  gap: 40px;

  @include respond(tabletS) {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .content {
    width: 100%;
    .item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;

      .itemContent {
        width: 100%;
      }

      .title {
        font-weight: 400;
        font-size: 17px;
        line-height: 22px;
        color: rgba(23, 33, 43, 0.36);
        margin-bottom: 4px;
      }

      .text {
        font-weight: 400;
        font-size: 17px;
        line-height: 22px;
        letter-spacing: -0.22px;
        color: #17212b;
        word-break: break-all;
      }

      .action {
        padding: 0;
        background: none;
        border: none;
        margin-left: 24px;
      }
    }

    .contentDescription {
      width: 100%;
      font-weight: 400;
      font-size: 17px;
      line-height: 22px;
      letter-spacing: -0.22px;
      color: #7d8288;
    }
  }
}
