@import 'assets/styles/mixins.scss';

.content__company {
  display: flex;
  align-items: center;
  padding: 20px 40px;

  @include respond(tabletS) {
    padding: 20px 0px;
    border-top: 1px solid #f6f6f7;
    justify-content: center;
  }

  .content__company-wrapper {
    display: flex;
    align-items: center;
    column-gap: 8px;

    .company__text {
      .text__name {
        @include tSubtitle;
        color: rgba(23, 33, 43, 0.36);
      }
    }

    .company__logo {
      display: flex;
      align-items: center;
      justify-content: center;

      @include tSubtitle;
      color: #0d1019;
    }
  }
}
