@import '../../../../assets/styles/mixins.scss';

.subtitle-wrapper {
  display: grid;
  align-items: center;
  grid-template-columns: 48px auto;
  grid-template-areas: 'logo text';
  column-gap: 12px;
  margin-bottom: 32px;

  @include respond(tabletS) {
    grid-template-columns: 36px auto auto;
    grid-template-areas: 'button text logo';
    justify-content: space-between;
  }

  .subtitle {
    grid-area: text;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 1.33;
    letter-spacing: -0.18px;
    color: #17212b;

    @include respond(tabletS) {
      font-size: 20px;
      line-height: 1.3;
    }
  }

  .icon__back-wrapper {
    grid-area: button;
  }

  .icon__wrapper {
    grid-area: logo;
  }
}

.card__input-wrapper {
  margin-bottom: 24px;
}

.card__number {
  max-width: 544px;
}

.card__date-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;
  max-width: 544px;
}

.button__payment {
  margin-bottom: 12px;
}

x .card__input-cheque {
  position: relative;
  input {
    padding-top: 26px;
  }
}

.card__cheque {
  position: absolute;
  top: 11px;
  left: 16px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.17;
  color: rgba(23, 33, 43, 0.56);
}

.icon__back-wrapper {
  display: none;

  @include respond(tabletS) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.metod-wrapper {
  @include respond(tabletS) {
    // padding: 24px 24px 50px;
  }
}

.icon__wrapper {
  display: flex;
  align-items: center;

  @include respond(tabletS) {
    flex-direction: row-reverse;
  }

  svg {
    width: 48px;
    height: 48px;
  }

  &.perfect-money {
    svg {
      color: #f01010;
    }
  }
}
