@import 'assets/styles/mixins.scss';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  max-width: 456px;
  width: 100%;
  min-height: 150px;
  z-index: 11;
  background-color: #ffffff;
  border-radius: 28px;
  overflow: hidden;
  position: relative;

  @include respond(tabletS) {
    max-width: 100%;
    min-height: calc(var(--vh, 1vh) * 100);
    transition: 0.1s linear;
    border-radius: 0;
    overflow: scroll;
  }

  .modalControl {
    .closeButton {
      position: absolute;
      top: 8px;
      right: 8px;
      color: #161a1e;
    }
  }

  .modalContent {
    padding: 32px;
    height: inherit;
    box-sizing: border-box;

    @include respond(tabletS) {
      padding: 16px;
    }
  }
}
