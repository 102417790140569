@import 'assets/styles/mixins.scss';

.layout {
  max-width: 968px;
  // width: max-content;
  // width: ;
  margin: 0 auto;
  padding-left: 16px;
  padding-right: 16px;
  display: grid;
  grid-template-rows: max-content minmax(200px, 754px) max-content;

  @include respond(tabletS) {
    grid-template-rows: max-content 1fr max-content;
    min-height: 100vh;
    height: 100%;
    padding: 0;

    display: flex;
    flex-direction: column;
    height: auto;
  }

  .invoiceBody {
    background-color: white;
    border-radius: 24px;
    overflow: hidden;
    position: relative;
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding: 24px;

    @include respond(tabletS) {
      display: grid;
      grid-template-rows: 1fr max-content;
      grid-template-columns: 1fr;
      height: 100%;
    }

    .items__title {
      padding: 24px 16px;
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: -0.18px;
      color: #17212b;
    }

    .invoice__error-page {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      padding: 150px 0;
    }
  }

  .footer__hotline {
    grid-area: b;
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    @include respond(tabletS) {
      align-items: flex-start;
    }

    a {
      font-family: 'Inter';
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 1.17;

      text-align: right;
      letter-spacing: -0.89px;

      color: #17212b;
    }
  }

  .footer__language {
    grid-area: c;
    margin-bottom: 32px;
  }
}
