@import 'assets/styles/mixins.scss';

.invoice__content {
  display: flex;
  justify-content: center;
  height: 100%;
  position: relative;

  @include respond(tabletS) {
    grid-template-columns: 100%;
    height: auto;
    column-gap: 0px;
    display: grid;
    padding: 0 16px;
  }

  .content__sidebar {
    overflow: auto;
    border-radius: 24px;
    max-width: 304px;

    &::-webkit-scrollbar {
      display: none !important; /* for Chrome, Safari, and Opera */
    }

    @include respond(tabletS) {
      max-height: 100%;
      max-width: 100%;
      overflow-y: auto;
      position: absolute;
      z-index: 10;
      background: white;
      width: 100%;
    }
  }

  .content__sidebar::after {
    content: '';
    position: absolute;
    bottom: 0;
    height: 62px;
    display: grid;
    grid-template-columns: minmax(233px, 337px);
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, #ffffff 100%);
    box-shadow: 0px -10px 10px rgba(255, 255, 255, 0.1);
    border-radius: 24px 24px 0px 0px;

    @include respond(tabletL) {
      grid-template-columns: minmax(233px, 245px);
    }

    @include respond(tabletS) {
      display: none;
    }
  }

  .MuiDivider-root {
    width: 1px;
    color: #e8e9ea;

    @include respond(tabletS) {
      display: none;
    }
  }

  .content__form {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    max-width: 872px;
    width: 100%;
    margin: 0 auto;

    @include respond(tabletS) {
      padding: 0px;
      z-index: 1;
      padding: 16px 0px;
    }

    .content__form-wrapper {
      max-width: 544px;
      width: 100%;
      height: 100%;
      box-sizing: border-box;

      @include respond(tablet) {
        padding-left: 24px;
        padding-right: 24px;
      }

      @include respond(tabletS) {
        border-left: none;
        padding: 0px;
      }
    }
  }

  .scroll::-webkit-scrollbar {
    width: 3px;
  }

  .scroll::-webkit-scrollbar-thumb {
    border-radius: 512px;
    background-color: rgba(23, 33, 43, 0.12);
  }

  .scroll::-webkit-scrollbar-track {
    margin-top: 24px;
    margin-bottom: 62px;
    width: 3px;
  }

  .shadow-top {
    display: none;
    @include respond(tabletS) {
      position: absolute;
      top: 0;
      width: 98%;
      height: 30px;
      background: linear-gradient(0deg, rgba(255, 255, 255, 0.1) 0%, #ffffff 100%);
      z-index: 122;
      box-shadow: 0px 10px 10px rgba(255, 255, 255, 0.1);
      border-radius: 24px 24px 0px 0px;
    }
  }

  .shadow-bottom {
    display: none;

    @include respond(tabletS) {
      position: absolute;
      bottom: 0;
      width: 98%;
      height: 26px;
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, #ffffff 100%);
      z-index: 122;
      box-shadow: 0px -10px 10px rgba(255, 255, 255, 0.1);
      border-radius: 0px 0px 24px 24px;
    }
  }
}
