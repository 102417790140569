// Mixins for developer
@mixin btest {
  border: 1px solid red;
}

@mixin mtest {
  margin: 10px 0;
}

// Media Queries Mixins
@mixin respond($breakpoint) {
  @if $breakpoint==laptop {
    @media only screen and (max-width: 1244px) {
      @content;
    }
  }

  @if $breakpoint==laptopL {
    @media only screen and (max-width: 1024px) {
      @content;
    }
  }

  @if $breakpoint==tabletL {
    @media only screen and (max-width: 840px) {
      @content;
    }
  }

  @if $breakpoint==tablet {
    @media only screen and (max-width: 768px) {
      @content;
    }
  }

  @if $breakpoint==tabletS {
    @media only screen and (max-width: 576px) {
      @content;
    }
  }

  @if $breakpoint==mobileL {
    @media only screen and (max-width: 425px) {
      @content;
    }
  }

  @if $breakpoint==mobile {
    @media only screen and (max-width: 375px) {
      @content;
    }
  }
}

// Typography Mixins
@mixin tCaption {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: -0.09px;
}

@mixin tCaption_2 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.02em;
}

@mixin tCaption_3 {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  letter-spacing: -0.22px;
}

@mixin tSubtitle {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: -0.22px;
}

@mixin Inter_15_600 {
  font-family: 'Inter', sans-serif;
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.13px;
}

@mixin tAmount {
  font-family: 'Inter', sans-serif;
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px; /* 100% */
  letter-spacing: -0.79px;

  @include respond(mobileL) {
    font-size: 28px;
    line-height: 28px;
  }
}

@mixin tSubtitle_2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 28px;
  text-align: right;
  letter-spacing: -0.89px;
}

@mixin tTitle_2 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.18px;

  @include respond(tabletS) {
    font-size: 20px;
    line-height: 26px;
  }
}

// Smooth Transition
@mixin transition {
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
