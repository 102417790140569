@import 'assets/styles/mixins.scss';

.item {
  background-color: transparent;
  border: none;
  border-radius: 28px;
  overflow: hidden;
  padding: 12px;
  min-width: 56px;

  transition: border-radius 0.2s ease-in-out;

  &:hover {
    background-color: #f6f6f7;
  }

  &.active {
    background-color: #f6f6f7;
    color: #17212b;
  }
  &.isExpanded {
    border-radius: 12px;
  }

  .link {
    color: rgba(23, 33, 43, 0.56);
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    letter-spacing: -0.18px;
    @include transition;
    cursor: pointer;
    text-decoration: none;
    column-gap: 12px;
    display: flex;
    align-items: center;
    margin: 0 auto;

    @include respond(tabletS) {
      color: #17212b;
    }

    .image {
      @include transition;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .name {
      white-space: nowrap;
    }
  }
}

.list__item:not(:last-child) {
  margin-bottom: 4px;
}
