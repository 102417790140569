@import 'assets/styles/mixins.scss';

.buttonPreview {
  // box-shadow: 0px 16px 44px rgba(89, 102, 132, 0.1);
  border-radius: 10px;
  font-family: 'Inter';
  box-sizing: border-box;
  font-style: normal;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  @include transition;
  cursor: pointer;

  max-width: 414px;

  .text {
    margin-left: 8px;
    font-weight: 600;
    line-height: 24px;
    font-size: inherit;
  }

  .image {
    svg {
      width: 14px;
      height: auto;
      @include transition;
    }
  }

  &.small {
    .image {
      svg {
        width: 12px;
      }
    }
  }

  &.black {
    color: #ffffff;
    background-color: #0d1019;

    .image {
      svg {
        color: #ffdf54;
      }
    }
  }

  &.white {
    color: #111111;
    background-color: #ffdf54;
  }
}
