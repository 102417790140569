@import 'assets/styles/mixins.scss';

.container {
  padding: 20px;

  background: #17212b0a;
  border-radius: 20px;

  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .loader {
      width: 48px;
      height: 48px;
      margin-bottom: 24px;
      color: #d9d9d9;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .text,
    .subtext {
      font-weight: 400;
      font-size: 17px;
      line-height: 24px;
      text-align: center;
      letter-spacing: -0.22px;
      color: #17212b;
      display: flex;
      align-items: flex-end;
    }

    .dots {
      position: relative;
      width: 3px;
      height: 3px;
      border-radius: 5px;
      margin-bottom: 5px;
      margin-left: 2px;
      background-color: #17212b;
      color: #17212b;
      animation: dotFlashing 1s infinite linear alternate;
      animation-delay: 0s;
    }

    .dots::before,
    .dots::after {
      content: '';
      display: inline-block;
      position: absolute;
      top: 0;
    }

    .dots::before {
      left: 6px;
      width: 3px;
      height: 3px;
      border-radius: 5px;
      background-color: #17212b;
      color: #17212b;
      animation: dotFlashing 1s infinite alternate;
      animation-delay: 0.5s;
    }

    .dots::after {
      left: 12px;
      width: 3px;
      height: 3px;
      border-radius: 5px;
      background-color: #17212b;
      color: #17212b;
      animation: dotFlashing 1s infinite alternate;
      animation-delay: 1s;
    }

    @keyframes dotFlashing {
      0% {
        background-color: #17212b;
      }
      50%,
      100% {
        background-color: #b6b4b4;
      }
    }
  }
}
